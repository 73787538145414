<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12" md="3" lg="2">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Start Date"
                outlined
                flat
                dense
                background-color="white"
                v-model="params.startDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="params.startDate"
              @input="menuStartDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="3" lg="2">
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="End Date"
                outlined
                flat
                dense
                background-color="white"
                v-model="params.endDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="params.endDate" @input="menuEndDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="3" lg="2">
          <v-btn @click="search" color="primary" class="mr-2">
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
          <v-btn @click="excel" color="success">
            <v-icon>
              mdi-file-excel
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="items"
        :search="filter"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:top>
          <v-row>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-text-field
                flat
                dense
                outlined
                background-color="white"
                v-model="filter"
                placeholder="search"
                append-icon="mdi-magnify"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.no="{ index }">
          {{ index + 1 }}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import fileDownload from "js-file-download";
import moment from "moment";

export default {
  name: "report-ap-tax",
  data() {
    return {
      menuStartDate: false,
      menuEndDate: false,
      params: {
        startDate: moment()
          .startOf("month")
          .format("yyyy-MM-DD"),
        endDate: moment().format("yyyy-MM-DD"),
      },
      items: [],
      filter: "",
      headers: [
        {
          text: "No",
          align: "center",
          value: "no",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Tanggal",
          align: "center",
          value: "date",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Keterangan",
          align: "left",
          value: "remark",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Ref",
          align: "center",
          value: "reference",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Nama Supplier",
          align: "center",
          value: "vendorName",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "NPWP",
          align: "center",
          value: "npwp",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Alamat Supplier",
          align: "left",
          value: "address",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "No Kontak",
          align: "left",
          value: "contactName",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Jenis Barang",
          align: "center",
          value: "type",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
    };
  },
  methods: {
    async search() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/apTax", this.params)
        .then(response => {
          this.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async excel() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/apTaxExcel", this.params)
        .then(response => {
          fileDownload(response.data, `report-ap-tax.xlsx`);
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
};
</script>

<style scoped>
table,
th,
td {
  border: 1px solid grey;
}
</style>
